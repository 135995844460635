import { call, put, fork, take } from 'redux-saga/effects';
import { recentOrders as recentOrdersActions, chartOrders as chartOrdersActions, resetDashboard } from './actions';
import ActionTypes from './constants';
import { getOrderStatistics, getOrderTotalSummary } from 'utils/apollo';
import MainActionTypes from 'containers/MainLayout/constants';
import moment from 'moment';
import { getStoreWithRetry } from 'containers/MainLayout/saga';
import dayjs from 'dayjs';

function* recentOrders() {
  const store = yield call(getStoreWithRetry);

  if (!store) {
    return;
  }

  const countStart = moment().startOf('day').toISOString();

  const countEnd = moment().endOf('day').toISOString();

  const aggregateStart = moment().startOf('month').toISOString();

  const aggregateEnd = moment().endOf('month').toISOString();

  yield put(recentOrdersActions.request());

  const response = yield call(getOrderStatistics, {
    storeId: store.id,
    countStart: countStart,
    countEnd: countEnd,
    aggregateStart: aggregateStart,
    aggregateEnd: aggregateEnd,
  });

  if (!response.errors) {
    yield put(
      recentOrdersActions.success({
        ...response,
        orderTotalSummary: {
          data: response.orderTotalSummary,
        },
      }),
    );
  } else {
    yield put(recentOrdersActions.failure(response.errors));
  }
}

function* initData() {
  while (true) {
    yield take(MainActionTypes.SET_SELECTED_STORE);
    yield put(resetDashboard());
    yield call(recentOrders);
  }
}

function* chartOrders() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHANGE_CHART_QUERY);
    const store = yield call(getStoreWithRetry);

    if (!store) {
      return;
    }

    const start = dayjs(payload).startOf('month').toISOString();
    const end = dayjs(payload).endOf('month').toISOString();

    yield put(chartOrdersActions.request());
    const response = yield call(getOrderTotalSummary, {
      storeId: store.id,
      orderDateRange: {
        start: start,
        end: end,
      },
    });
    if (!response.errors) {
      yield put(
        chartOrdersActions.success({
          selectedMonthOfChart: payload,
          orderTotalSummary: {
            data: response,
          },
        }),
      );
    } else {
      yield put(chartOrdersActions.failure(response.errors));
    }
  }
}

// Individual exports for testing
export default function* dashboardPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(recentOrders);
  yield fork(initData);
  yield fork(chartOrders);
}
