/*
 *
 * DashboardPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import moment from 'moment';

const loadingResources = Array(10)
  .fill(0)
  .map((v, i) => ({ loading: true, id: i }));

export const initialState: ContainerState = {
  recentOrders: {
    selectedMonthOfChart: moment().startOf('month'),
    loading: true,
    orders: {
      data: loadingResources,
      loading: true,
    },
    orderTotalSummary: {
      data: [],
      loading: true,
    },
    orderCountSummary: {
      loading: true,
      orderedWithinPeriod: 0,
      deliverWithinPeriod: 0,
      pending: 0,
    },
  },
};

function dashboardPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.FETCH_RECENT_ORDERS_SUCCESS:
      return {
        ...state,
        recentOrders: {
          ...state.recentOrders,
          ...action.payload,
        },
      };
    case ActionTypes.FETCH_CHART_ORDERS_SUCCESS:
      return {
        ...state,
        recentOrders: {
          ...state.recentOrders,
          ...action.payload,
        },
      };
    case ActionTypes.RESET_DASHBOARD:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default dashboardPageReducer;
