interface Number {
  toHumanReadableFormat(): string;
}

Number.prototype.toHumanReadableFormat = function(): string {
  const valueStr = `${this}`;
  if (this < 1_000) {
    return valueStr;
  }
  if (this < 1_000_000) {
    return `${Number(`${valueStr.slice(0, -3)}.${valueStr.slice(-3)}`)}K`;
  }
  if (this < 1_000_000_000) {
    return `${Number(`${valueStr.slice(0, -6)}.${valueStr.slice(-6)}`)}M`;
  }
  return `${Number(`${valueStr.slice(0, -9)}.${valueStr.slice(-9)}`)}B`;
};
