/**
 *
 * DashboardPage
 *
 */

import { Dispatch, compose } from 'redux';
import React, { Fragment } from 'react';
import { selectStore, selectUserPermissions, selectPendingApprovalMode } from 'containers/MainLayout/selectors';

import Button from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';
import Greetings from './GREETINGS.svg';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Overview from 'components/Overview/Overview';
import { RootState } from './types';
import { changeChartQuery } from './actions';
import { connect } from 'react-redux';
// import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectDashboardPage from './selectors';
import styled from 'styled-components';
import translations from 'translations';
import utilsMessages from 'utils/messages';
interface OwnProps {
  dashboardpage: any;
  store: any;
  pendingMode: boolean;
  permissions: string[];
}

interface StateProps {}

interface DispatchProps {
  onChangeMonthOfChart: (data: any) => void;
  gotoOrdersHistory: (data: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;
interface State {
  bannerPopupVisible: boolean;
}

const EmptyOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 65%;
  }
`;
const EmptyOverview = styled.div`
  width: 540px;
  text-align: center;
  p {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 23px;
    padding: 16px;
  }
`;
export class DashboardPage extends React.PureComponent<Props, State> {
  public onChangeMonthOfChart = (data: any) => {
    this.props.onChangeMonthOfChart(data);
  };

  public onGoToOrdersHistory = (date) => {
    this.props.gotoOrdersHistory(
      `statuses=ACCEPTED&statuses=SENT&statuses=DELIVERED&orderDateStart=${date
        .startOf('d')
        .toISOString()}&orderDateEnd=${date.endOf('d').toISOString()}`,
    );
  };

  public render() {
    const {
      dashboardpage: { recentOrders, selectedMonthOfChart },
      permissions,
      pendingMode,
    } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {!recentOrders.orders.loading && !recentOrders.orders.data.length ? (
          <EmptyOverviewContainer>
            <EmptyOverview>
              <img src={Greetings} alt="Greetings" />
              <p>
                <FormattedMessage {...messages.welcomeKamereo} />
                <br />
                <FormattedMessage {...messages.firstOrder} />
              </p>
            </EmptyOverview>
            <Link to="/market">
              <Button type="primary">
                <FormattedMessage {...utilsMessages.goToMart} />
              </Button>
            </Link>
          </EmptyOverviewContainer>
        ) : (
          <Overview
            pendingMode={pendingMode}
            recentOrders={recentOrders}
            onChangeMonthOfChart={this.onChangeMonthOfChart}
            onGoToOrdersHistory={this.onGoToOrdersHistory}
            selectedMonthOfChart={selectedMonthOfChart}
            permissions={permissions}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  dashboardpage: selectDashboardPage(),
  store: selectStore(),
  permissions: selectUserPermissions(),
  pendingMode: selectPendingApprovalMode(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangeMonthOfChart: (data: any) => dispatch(changeChartQuery(data)),
    gotoOrdersHistory: (query: string) => dispatch(push(`/orders?${query}`)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'dashboardPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'dashboardPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(DashboardPage);
