/*
 *
 * DashboardPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const recentOrders = createAsyncAction(
  ActionTypes.FETCH_RECENT_ORDERS_REQUEST,
  ActionTypes.FETCH_RECENT_ORDERS_SUCCESS,
  ActionTypes.FETCH_RECENT_ORDERS_FAILURE,
)<void, {}, Error>();

export const changeChartQuery = (data: any) => action(ActionTypes.CHANGE_CHART_QUERY, data);

export const resetDashboard = () => action(ActionTypes.RESET_DASHBOARD);

export const chartOrders = createAsyncAction(
  ActionTypes.FETCH_CHART_ORDERS_REQUEST,
  ActionTypes.FETCH_CHART_ORDERS_SUCCESS,
  ActionTypes.FETCH_CHART_ORDERS_FAILURE,
)<void, {}, Error>();
