import * as React from 'react';
import { Card as ACard, Statistic } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ResponsiveContainer, CartesianGrid, BarChart, Bar, LabelList, XAxis, YAxis, Tooltip } from 'recharts';
import Calendar from 'components/TrackingComponents/Calendar/Calendar';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import formatMoney from 'utils/formatMoney';
import messages from 'containers/OrdersPage/messages';
import Money from 'components/Money/Money';
import '../../utils/numbers';
import dayjs from 'dayjs';

const dates =
  window.localStorage.getItem('lang') === 'vi'
    ? ['CH', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7']
    : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

function getChartData(startDay, rawData: any[]) {
  const lastDay = startDay.daysInMonth() + 1;

  const lenght = lastDay;
  const data: any[] = [];
  const dataObject = {};
  rawData.forEach((item) => {
    const value = `${item.totalAmount}`;
    const startTime = dayjs(item.startTime);
    dataObject[startTime.date() - 1] = {
      value: value,
      fullDate: startTime.format('DD/MM/YYYY'),
      rawValue: value,
    };
  });
  for (let i = 1, j = startDay.day(); i < lenght; i += 1, j += 1) {
    if (j === 7) {
      j = 0;
    }
    data.push({
      day: i,
      weekday: dates[j],
      ...(dataObject[i - 1] ? dataObject[i - 1] : { value: 0, rawValue: 0 }),
    });
  }

  return data;
}

const ChartContainer = styled.div`
  overflow: hidden;
  overflow-x: auto;
`;

const CalendarContainer = styled.div`
  width: 80pt;
  ${media.sm`
    min-width: 140pt;
  `};
`;

const Card = styled(ACard)`
  &&& {
    margin-bottom: 8pt;
  }
  .ant-card-head {
    border: none;
  }
  &:hover {
    background: white;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const labelListFormatter = (props: any) => {
  const { x, y, width, height, value } = props;
  return (
    <g>
      <text
        x={x + width / 2}
        y={y + height + 28}
        fill={value === 'SUN' || value === 'SAT' || value === 'T7' || value === 'CH' ? 'red' : 'rgba(0, 0, 0, 0.54)'}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={10}
      >
        {value}
      </text>
    </g>
  );
};
const TriangleBar = (props: { fill?: string; x?: number; y?: number; width?: number; height?: number }) => {
  const { fill, x, y, width, height } = props;

  return <rect style={{ cursor: 'pointer' }} x={x} y={y} width={width} height={height} rx="6" fill={fill} />;
};

const CustomTooltip = ({ active, payload, label }) => {
  const value = payload && payload[0] && payload[0].payload ? +payload[0].payload.rawValue || 0 : 0;
  if (active && value > 0) {
    return (
      <Card style={{ background: '#337E75' }} bordered={false}>
        <Statistic
          title={<span style={{ color: '#fff' }}>{payload[0].payload.fullDate}</span>}
          value={`${formatMoney(value)}đ`}
          valueStyle={{ fontWeight: 'bold', color: '#fff' }}
        />
      </Card>
    );
  }
  return null;
};

export default function ({
  loading,
  data: rawData,
  onChangeMonth,
  onGoToOrdersHistory,
  selectedMonth,
}: {
  loading: boolean;
  data: any[];
  onChangeMonth: (data: any) => void;
  onGoToOrdersHistory: (day: any) => void;
  selectedMonth: string;
}) {
  const startDay = dayjs(selectedMonth).startOf('month');
  const data = getChartData(startDay, rawData);
  const total = rawData.reduce((prev, curr) => prev + curr.totalAmount, 0);
  const onClickBar = (data, index) => {
    onGoToOrdersHistory(dayjs(data.fullDate, 'DD-MM-YYYY'));
  };

  return (
    <FormattedMessage id={messages.totalPurchase.id}>
      {(totalPurchase: string) => (
        <Card
          loading={loading}
          bordered={false}
          title={
            <FlexDiv>
              <span>
                {totalPurchase}
                &nbsp;
              </span>
              <Money>{total}</Money>
            </FlexDiv>
          }
          extra={
            <CalendarContainer>
              <Calendar
                trackingCategory="Activity Report | MonthPicker"
                trackingAction="Search Month"
                allowClear={false}
                size="large"
                month
                placeholder="Select month"
                value={startDay}
                onChange={onChangeMonth}
              />
            </CalendarContainer>
          }
        >
          <ChartContainer>
            <div style={{ minWidth: 1024 }}>
              <ResponsiveContainer width={'100%'} height={380}>
                <BarChart data={data} barSize={12}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <Bar onClick={onClickBar} dataKey="value" fill="#50C5B7" shape={<TriangleBar />}>
                    <LabelList dataKey="weekday" position="bottom" offset={24} content={labelListFormatter} />
                  </Bar>
                  <Tooltip isAnimationActive={false} cursor={false} content={CustomTooltip} />
                  <XAxis axisLine={false} tickLine={false} dataKey="day" />
                  <YAxis
                    width={40}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(value: any) => Number(value).toHumanReadableFormat()}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </ChartContainer>
        </Card>
      )}
    </FormattedMessage>
  );
}
