import * as React from 'react';

import { Card as ACard } from 'antd';
import SVGIcon from '../SVGIcon/SVGIcon';
import media from 'utils/mediaStyle';
import styled from 'styled-components';

const Card = styled(ACard)`
  &&& {
    margin-bottom: 8pt;
  }
  .ant-card-body {
    padding: 8pt;
    ${media.sm`
      padding: 8pt;
    `};
  }
`;
const Value = styled.span`
  font-size: 32px;
  color: ${({ color }) => color};
`;
const Label = styled.span`
  font-size: 14px;
  color: ${({ color }) => color};
  text-transform: uppercase;
`;
const Icon = styled(SVGIcon)<any>``;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function({
  color,
  icon,
  value,
  label,
  loading,
}: {
  color: string;
  icon: string;
  label: string;
  value: number;
  loading: boolean;
}) {
  return (
    <Card loading={loading} bordered={false}>
      <InfoWrapper>
        <Info>
          <Value color={color}>{value || 0}</Value>
          <Label color={color}>{label}</Label>
        </Info>
        <Icon color={color} src={icon} />
      </InfoWrapper>
    </Card>
  );
}
