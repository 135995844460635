enum ActionTypes {
  FETCH_RECENT_ORDERS_REQUEST = 'app/DashboardPage/FETCH_RECENT_ORDERS_REQUEST',
  FETCH_RECENT_ORDERS_SUCCESS = 'app/DashboardPage/FETCH_RECENT_ORDERS_SUCCESS',
  FETCH_RECENT_ORDERS_FAILURE = 'app/DashboardPage/FETCH_RECENT_ORDERS_FAILURE',
  CHANGE_CHART_QUERY = 'app/DashboardPage/CHANGE_CHART_QUERY',
  FETCH_CHART_ORDERS_REQUEST = 'app/DashboardPage/FETCH_CHART_ORDERS_REQUEST',
  FETCH_CHART_ORDERS_SUCCESS = 'app/DashboardPage/FETCH_CHART_ORDERS_SUCCESS',
  FETCH_CHART_ORDERS_FAILURE = 'app/DashboardPage/FETCH_CHART_ORDERS_FAILURE',
  RESET_DASHBOARD = 'app/MainLayout/RESET_DASHBOARD',
}

export default ActionTypes;
