import * as React from 'react';

import { Col, Row } from 'antd';

import Activity from './Activity';
import CardLink from 'components/CardLink/CardLink';
import CardOverview from './CardOverview';
import Delivery from './delivery.svg';
import { FormattedMessage } from 'react-intl';
import OrderTable from '../OrderTable/OrderTable';
import Orders from './Orders.svg';
import Pending from './pending.svg';
import messages from 'containers/OrdersPage/messages';
import moment from 'moment';
import styled from 'styled-components';

const Container = styled.div``;
const Header = styled.h3`
  margin-top: 12pt;
  margin-bottom: 12pt;
  text-transform: uppercase;
  font-weight: bold;
`;

const viewOrderPermission = 'ORDER_LIST';

export default (props: any) => {
  const {
    recentOrders: {
      selectedMonthOfChart,
      orders: { data: orders },
      orderTotalSummary,
      orderCountSummary: {
        loading: loadingOrderCountSummary,
        orderedWithinPeriod: orderedTodayCount,
        deliverWithinPeriod: deliveryTodayCount,
        pendingApproval: pendingApprovalCount,
      },
    },
    permissions,
    pendingMode,
  } = props;

  const today = moment();
  return (
    <Container>
      <Row gutter={8}>
        <Col xs={24} lg={pendingMode ? 8 : 12}>
          <CardLink
            loading={loadingOrderCountSummary}
            to={`/orders?filter[deliveryDateRange][start]=${today.startOf('day').toISOString()}&deliveryDateEnd=${today
              .endOf('day')
              .toISOString()}`}
          >
            <FormattedMessage id={messages.deliveryToday.id}>
              {(deliveryToday: string) => (
                <CardOverview
                  loading={loadingOrderCountSummary}
                  icon={Delivery}
                  color="#2E904E"
                  label={deliveryToday}
                  value={deliveryTodayCount}
                />
              )}
            </FormattedMessage>
          </CardLink>
        </Col>
        <Col xs={24} lg={pendingMode ? 8 : 12}>
          <CardLink
            loading={loadingOrderCountSummary}
            to={`/orders?orderDateStart=${today.startOf('day').toISOString()}&orderDateEnd=${today
              .endOf('day')
              .toISOString()}`}
          >
            <FormattedMessage id={messages.ordersToday.id}>
              {(ordersToday: string) => (
                <CardOverview
                  loading={loadingOrderCountSummary}
                  icon={Orders}
                  color="#40A9FF"
                  label={ordersToday}
                  value={orderedTodayCount}
                />
              )}
            </FormattedMessage>
          </CardLink>
        </Col>
        {pendingMode && (
          <Col lg={8}>
            <CardLink loading={loadingOrderCountSummary} to={`/orders-pending-approval`}>
              <FormattedMessage id={messages.pendingApprovalOrders.id}>
                {(pendingOrder: string) => (
                  <CardOverview
                    loading={loadingOrderCountSummary}
                    icon={Pending}
                    color="#D4B106"
                    label={pendingOrder}
                    value={pendingApprovalCount}
                  />
                )}
              </FormattedMessage>
            </CardLink>
          </Col>
        )}
      </Row>
      {permissions.indexOf(viewOrderPermission) > -1 && (
        <>
          <Header>
            <FormattedMessage {...messages.recentOrders} />
          </Header>
          <OrderTable orders={orders} />
        </>
      )}

      <Header>
        <FormattedMessage {...messages.activityReport} />
      </Header>
      <Activity
        {...orderTotalSummary}
        onChangeMonth={props.onChangeMonthOfChart}
        onGoToOrdersHistory={props.onGoToOrdersHistory}
        selectedMonth={selectedMonthOfChart}
      />
    </Container>
  );
};
